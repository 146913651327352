<template>
  <vue-good-table
    class="mt-3"
    mode="remote"
    :is-loading="isLoading"
    :columns="columns"
    :rows="rows"
    :rtl="isRtl"
    :search-options="{
      enabled: false,
    }"
    :pagination-options="{
      enabled: true,
      perPage: perPage,
    }"
    style-class="vgt-table striped"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: type -->
      <span v-if="props.column.field === 'type'">
        <b-badge :variant="typeVariant(props.row.type)">
          {{ props.row.type }}
        </b-badge>
      </span>
      <!-- Column: Actions -->
      <div
        v-else-if="props.column.label === 'Actions'"
        cols="12"
        md="2"
        class="d-flex vgt-center-align"
      >
        <b-button 
          variant="outline-none" 
          :to="{ name: 'apps-edit-knowledgebase', params: { id: props.row.id }}"
        > 
          <feather-icon
            icon="EditIcon"
            size="20"
          />
        </b-button>

        <b-button variant="outline-none">
          <feather-icon
            icon="TrashIcon"
            size="20"
          />
        </b-button>
      </div>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>

    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="
          (value) => props.pageChanged({ currentPage: value })
        "
      />
    </template>
  </vue-good-table>
</template>

<script>
import {
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
  GOODTABLE_LOCALISED_DATE_FORMAT,
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
} from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";

import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";

import {
  BBadge,
  BButton,
  VBToggle,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";

export default {
  components: {
    VueGoodTable,
    BBadge,
    BButton,
    TablePagination,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      isLoading: false,
      modalShow: false,
      perPage: 10,
      page: 1,
      columns: [
        {
          label: 'Title',
          field: 'title',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Title',
          },
        },

        {
          label: 'Type',
          field: 'type',
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: ["Tutorial", "Training", "Rejected", "Library", "Help"]
          },
          width: '9em',
        },
        {
          label: 'User Roles',
          field: 'access',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Roles',
          },
          width: '10em',
        },
        {
          label: 'Active Key Dates',
          field: 'activeKeyDate',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Key Dates',
          },
          width: '12em',
        },
        {
          label: 'Category',
          field: 'category',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Category',
          },
          width: '12em',
        },

        {
          label: 'Last Modified',
          field: 'lastModified',
            filterOptions: {
                enabled: true,
                placeholder: "Search Date",
                filterFn: this.dateFilter
            },
            tdClass: "search-date-selector",
            width: "10em",
            type: 'date',
            dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
            dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT
        },

        {
          label: 'Actions',
          field: 'id',
          sortable: false,
          tdClass: 'text-center',
          width: '8em',
        },
      ],
      rows: [],
      columnFilters: [],
      sort: [],
      total: 0,
    };
  },
  computed: {
    typeVariant() {
      const typeColor = {
        /* eslint-disable key-spacing */
        Tutorial      : 'light-primary',
        Training : 'light-success',
        Rejected     : 'light-danger',
        Library     : 'light-warning',
        Help      : 'light-info',
        /* eslint-enable key-spacing */
      };

      return type => typeColor[type];
    },
    isRtl() {
        return store.state.appConfig.isRTL;
    },
  },
  created() {
    this.$http.get('/good-table/knowledgebases')
      .then(res => { this.rows = res.data; });
  },
  mounted() {
    const inputs = document.getElementsByClassName('search-date-selector');
    inputs.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
        allowInput: true,
      });
    });
  },
  methods: {
    dateFilter(date, filterString) {
      return (date = Date.parse(date) >= Date.parse(filterString));
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        'title',
        'type',
        'activeKeyDate',
        'category',
        'lastModified',
      ]) {
        if (params.columnFilters[col]) {
          columnFilters.push({
            field: col,
            value: params.columnFilters[col],
          });
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },

    async loadItems() {
          // TODO: Pending create Service for Knowledgebase and connect to real DB
          /*
            try {
                this.isLoading = true;
                const response = await knowledgebaseService.getList({
                    page: this.page,
                    perPage: this.perPage,
                    columnFilters: this.columnFilters,
                    sort: this.sort,
                });
                this.total = response.data.total;
                this.rows = response.data.items;
            } catch (e) {
                this.$log.error(e);
            }*/
          this.isLoading = true;
    },
  },
};
</script>

<style>
  .search-date-selector{
    text-align: center !important;
  }
</style>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>
