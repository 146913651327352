<template>
  <b-card>
    <!-- Table Top -->
    <b-row class="list-top-buttons">
      <b-col
        cols="30"
        md="12"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.add-new-item-sidebar
            variant="primary"
            :to="{ name: 'apps-resourcesCategories'}"
          >
            <span class="text-nowrap">Category List</span>
          </b-button>
          <b-button
            variant="primary"
            style="margin-left:10px;"
            :to="{ name: 'apps-add-knowledgebase'}"
          >
            <span class="text-nowrap">Add Knowledgebase</span>
          </b-button>
          
          <b-button
            class="ml-1"
            variant="outline-none"
            :to="{ name: 'apps-knowledgebases-card'}"
          >                   
            <feather-icon
              icon="GridIcon"
              size="20"
            />
          </b-button>

          
          <b-button
            variant="outline-none"
          >                   
            <feather-icon
              icon="MenuIcon"
              size="20"
            />
          </b-button>
        </div>
      </b-col>
    </b-row>

    <!-- table -->
    <knowledgebase-list />
  </b-card>
</template>

<script>
import {
  BButton,
  BRow, BCol, BCard, VBToggle,
} from 'bootstrap-vue';

import Ripple from "vue-ripple-directive";
import KnowledgebaseList from "./KnowledgebaseList.vue";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    KnowledgebaseList,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      modalShow: false,
      modalShowEdit: false,
      element : { },
      dir: false,
      rows: [],
      searchTerm: '',
    };
  },
  created() {
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'en',
        name: 'English',
      },
    ];
    /* eslint-disable global-require */

    return {
      locales,
    };
  },
};
</script>

<style lang="scss" scoped>
  .badge {
      font-weight: 400 !important;

  }
  table.vgt-table td {
      vertical-align: middle;
  }

</style>

